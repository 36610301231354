import { AutoDueDate, IssueTrackerSettings } from '@nimbly-technologies/nimbly-common';
import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './IssueTrackerSettings.actionTypes';

export const setUseAutoDueDate = (setting: boolean) => action(types.SET_USE_AUTO_DUE_DATE, setting);

export const setUseAutoAssignment = (setting: boolean) => action(types.SET_USE_AUTO_ASSIGNMENT, setting);

export const setHighlightRecurringIssues = (setting: boolean) => action(types.SET_HIGHLIGHT_RECURRING_ISSUES, setting);

export const setHighlightRecurringIssuesCount = (count: number) =>
	action(types.SET_HIGHLIGHT_RECURRING_ISSUES_COUNT, count);

export const setAllowAuditorEditIssueMeta = (setting: boolean) => action(types.SET_ALLOW_AUDITOR_EDIT_ISSUE, setting);

export const setAllowAutoEscalateIssue = (setting: boolean) => action(types.SET_ALLOW_AUTO_ESCALATION, setting);

export const fetchIssueTrackerSettings = createAsyncAction(
	types.FETCH_ISSUE_TRACKER_SETTINGS_REQUEST,
	types.FETCH_ISSUE_TRACKER_SETTINGS_SUCCESS,
	types.FETCH_ISSUE_TRACKER_SETTINGS_FAILED,
)<
	{ organizationId: string },
	{
		issueTrackerSettings: IssueTrackerSettings;
		defaultAutoDueDate: AutoDueDate | undefined;
	},
	{ error: string }
>();
