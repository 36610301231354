import { History } from 'history';
import { StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

// general
import { gmapsReducer } from 'reducers/gmaps';
import { screenReducer } from 'reducers/screen';
import { sidebarReducer } from 'reducers/sidebar';
import { stylingReducer } from 'reducers/styling';
import accountReducer from 'reducers/account';

// TODO: rename to authReducer
import { userReducer } from 'reducers/user/user.reducer';
import { organizationReducer } from '../reducers/organizations/organizations.reducer';

// dashboard
import { analyticsReducer } from 'reducers/analytics';
import dashboardReducer from 'reducers/dashboard';
import { apiReducer } from 'reducers/api';
import { inventoryReducer } from 'reducers/inventory';

// admin
import adminReducer from 'reducers/admin';
import { departmentsReducer } from '../reducers/departments/departments.reducer';
import { featureAccessReducer } from '../reducers/featureAccess/featureAccess.reducer';
import { customerFeedbackListReducer } from '../reducers/customerFeedback/customerFeedbackList.reducer';
import { departmentEditorReducer } from '../reducers/departmentEditor/departmentEditor.reducer';
import usersReducer from 'reducers/users/users.reducer';
import { auditorsReducer } from 'reducers/auditors/auditors.reducer';
import { sitesReducer } from 'reducers/sites/sites.reducer';
import siteReducer from '../reducers/site';
import { siteGroupsReducer } from 'reducers/site/siteGroups/siteGroups.reducers';
import { siteReportsReducer } from 'reducers/siteReports/siteReport.reducer';
import scheduleTemplateReducer from 'reducers/scheduleTemplate/scheduleTemplate.reducer';
import { questionnaireReducer } from 'reducers/questionnaire/questionnaire.reducer';
import { questionnaireDetailReducer } from '../reducers/questionnaireDetail/questionnaireDetail.reducer';
import questionnairesIndexReducer from 'reducers/questionnaireIndex/questionnaireIndex.reducer';
import { departmentGroupReducer } from '../reducers/departmentGroup/departmentGroup.reducer';
import { journeyPlanReducer } from '../reducers/journeyPlan';
import journeysReducer from '../reducers/journeyPlans/journeys.reducer';
import { companyReducer } from '../reducers/company/company.reducer';
import { productReducer } from '../reducers/product/product.reducer';
import { promotionReducer as competitorPromotionReducer } from '../reducers/competitorPromotion/promotion.reducer';
import { exportReducer } from '../reducers/competitorExport/competitorExport.reducer';
import poReducer from 'reducers/poEditor';
import { promotionReducer } from 'reducers/promotions/promotions.reducer';
import { downloadManagerReducer } from 'reducers/downloadManager/downloadManager.reducer';
import { salestargetReducer } from 'reducers/salesTarget/salestarget.reducer';
import { tutorialReducer } from 'reducers/tutorial/tutorial.reducer';
import { routeReducer } from 'reducers/routeAccess/reducer';
import { questionnaireByPropsReducer } from 'reducers/questionnaire/questionnaireByProps.reducer';
import { skuReducer } from 'reducers/skuManagement/skus.reducer';
import { skuCategoryReducer } from '../reducers/skuCategory/skuCategory.reducer';
import { skuUomReducer } from '../reducers/skuUom/skuUom.reducer';
import { schedulerReducer } from '../reducers/stockAuditScheduler/stockAuditScheduler.reducer';
import { skusCompactReducer } from 'reducers/skusCompact/skusCompact.reducer';
import { siteActivityHistoryReducer } from 'reducers/site/siteActivityHistory/siteActivityHistory.reducer';
import { siteHierarchyReducer } from 'reducers/site/siteHierarchy/siteHierarchy.reducer';
import { usersCompactReducer } from 'reducers/usersCompact/usersCompact.reducer';
import { reportsReducer } from 'reducers/reports/reports.reducer';
// issue tracker
import { issuesReducer } from '../reducers/issues/issues.reducer';

// settings
import { settingsReducer } from '../reducers/settings';
import { userAccessReducer } from '../reducers/useraccess';
// import { settingsModalReducer } from '../reducers/settingsModal';
import { integrationReducer } from '../reducers/integration/integration.reducer';

// superadmin
import { superadminReducer } from 'reducers/superadmin/superadmin.reducer';
import { clientLoginReducer } from 'reducers/superadmin/clientLogin/reducer';
import { reqForDemoReducer } from 'reducers/superadmin/reqForDemo/reducer';
import { errorlogReducer } from 'reducers/errorlog';
import { activityLogReducer } from 'reducers/superadmin/activitylog/reducer';

import { filterReducer } from 'reducers/filter';
import { customerFeedbackReportReducer } from 'reducers/customerFeedback/customerFeedbackReport.reducer';
import { siteCompactReducer } from 'reducers/site/siteCompact/siteCompact.reducer';
import { customerFeedbackStatusReducer } from 'reducers/customerFeedback/customerFeedbackStatus.reducer';
import { siteCompactPaginateReducer } from 'reducers/site/siteCompact/siteCompactPaginate.reducer';
import { customerFeedbackTemplateReducer } from 'reducers/customerFeedback/customerFeedbackTemplate.reducer';

// missed makeup report
import { missedMakeupReducer } from 'reducers/dashboard/missedMakeup/reducer';

// user enrollment
import { userEnrollmentReducer } from 'reducers/userEnrollment/userEnrollment.reducer';
import { formulasReducer } from 'reducers/formulas/formulas.reducer';
import { siteOffDaysReducer } from 'reducers/site/siteOffDays/siteOffDays.reducer';

// lms - user management
import { getUserListReducer } from 'reducers/lms/userManagement/userList/userList.reducer';
import { lmsCoursesReducer } from 'reducers/lms/userManagement/courses/courses.reducer';
import { addUserReducer } from 'reducers/lms/userManagement/roleMapping/addUser.reducer';
import { getUserGroupListReducer } from 'reducers/lms/userManagement/userGroupList/userGroupList.reducer';
import { createUserReducer } from 'reducers/lms/userManagement/createUser/user.reducer';
import { createUserGroupReducer } from 'reducers/lms/userManagement/createUserGroup/userGroup.reducer';
import { bulkUpdateUser } from 'reducers/lms/userManagement/bulkEditUser/bulkEdit.reducer';
import { getCourseListReducer } from 'reducers/lms/userManagement/enrollCourseUserGroup/enrollCourseUserGroup.reducer';
import { coursesReducer } from 'reducers/courses/courses.reducer';
import { syllabusesReducer } from 'reducers/syllabuses/syllabuses.reducer';
import { lmsCsManagementPageReducer } from 'reducers/lms/lmsCsManagementPage/lmsCsManagementPage.reducer';
import { lmsUsersCompactReducer } from 'reducers/lms/userManagement/lmsUsersCompact/lmsUsersCompact.reducer';
import { lmsCourseReducer } from 'reducers/lms/course/lmsCourse.reducer';
import { lmsMyCoursesAndSyllabusesReducer } from 'reducers/lms/myCourseAndSyllabuses/myCourseAndSyllabuses.reducer';

// lms - courses and syllabuses
import { lmsCoursesSyllabusesReducer } from 'reducers/lms/coursesSyllabuses/coursesSyllabuses.reducer';
import { lmsSyllabusesReducer } from 'reducers/lms/syllabuses/syllabuses.reducer';
import { createCourseReducer } from 'reducers/lms/course/createCourse/createCourse.reducer';
import { lmsLearnerActivityReducer } from 'reducers/lms/learnerActivity/lmsLearnerActivity.reducer';
import { lmsUserManagementUserReducer } from 'reducers/lms/userManagement/userById/lmsUserManagementUserById.reducer';
import { updateCourseReducer } from 'reducers/lms/course/updateCourse/updateCourse.reducer';
import { issueTrackerSettingsReducer } from 'reducers/settingsPage/IssueTrackerSettings.reducer';
import settingsNotificationReducer from 'reducers/settingsPage/settingsNotification/settingsNotification.reducer';
import { galleryReducer } from 'reducers/gallery/gallery.reducer';
import { quizManagementReducer } from 'reducers/lms/quizManagement/quizManagement.reducer';
import { quizzesReducer } from 'reducers/quizzes/quizzes.reducer';
import { quizAssociationOptionsReducer } from 'reducers/lms/quiz/quizAssociationOptions/quizAssociationOptions.reducer';
import { lmsCourseQuizReducer } from 'reducers/lms/course/courseQuiz/lmsCourseQuiz.reducer';
import { lmsNotificationReducer } from 'reducers/lms/notification/notification.reducer';
import { lmsDashboardReducer } from 'reducers/lms/lmsDashboard/lmsDashboard.reducer';
import { quizEditorReducer } from 'reducers/lms/quizEditor/quizEditor.reducer';
import { siteScheduleActivityReducer } from 'reducers/site/siteScheduleActivity/siteScheduleActivity.reducer';
import { healthDashboardReducer } from 'reducers/healthDashboard/healthDashboard.reducer';
import { reportsHubReducer } from 'reducers/reportsHub/reportsHub.reducer';
import { repositoryReducer } from 'reducers/repository/repository.reducer';
import { repositoryUserUsageReducer } from 'reducers/repositoryUserUsage/repositoryUserUsage.reducer';
import { repositoryOrganizationUsageReducer } from 'reducers/repositoryOrganizationUsage/repositoryOrganizationUsage.reducer';
import { repositoryFolderListReducer } from 'reducers/repositoryFolderList/repositoryFolderList.reducer';
import { executiveDashboardReducer } from 'reducers/dashboardRevamp/executive/executive.reducer';
import { operationalReducer } from 'reducers/dashboardRevamp/operational/operational.reducer';
import { reportInsightsReducer } from 'reducers/dashboardRevamp/report-insights/reportInsights.reducer';
import { issueInsightsReducer } from 'reducers/dashboardRevamp/issueInsights/issueInsights.reducer';
import { basicDashboardReducer } from 'reducers/dashboardRevamp/basicDashboard/basicDashboard.reducer';

import { userDeptCompactReducer } from 'reducers/userDept/userDept.reducer';
import { siteGroupCompactReducer } from 'reducers/siteGroup/siteGroup.reducer';
import { siteUserCompactReducer } from 'reducers/siteUser/siteUser.reducer';
import { userRolesCompactReducer } from 'reducers/userRoles/userRoles.reducer';
import { lmsSettingsReducer } from 'reducers/lmsSettings/lmsSettings.reducer';
import { stockAuditReducer } from 'reducers/dashboardRevamp/newInventory/stockAudit.reducer';
import { adminDashboardReducer } from 'reducers/adminDashboard/adminDashboard.reducer';

export const makeRootReducer = (history: History) => {
	return combineReducers({
		router: connectRouter(history),
		jwtauth: userReducer,
		firebase: firebaseReducer,
		firestore: firestoreReducer,
		gmaps: gmapsReducer,
		departments: departmentsReducer,
		auditors: auditorsReducer,
		styling: stylingReducer,
		sidebar: sidebarReducer,
		analytics: analyticsReducer,
		questionnaireByProps: questionnaireByPropsReducer,
		questionnaire: questionnaireReducer,
		questionnaireDetail: questionnaireDetailReducer,
		questionnaireIndex: questionnairesIndexReducer,
		salestarget: salestargetReducer,
		screen: screenReducer,
		api: apiReducer,
		issues: issuesReducer,
		inventory: inventoryReducer,
		sku: skuReducer,
		stockAuditScheduler: schedulerReducer,
		purchaseOrder: poReducer,
		site: siteReducer,
		siteGroups: siteGroupsReducer,
		dashboard: dashboardReducer,
		admin: adminReducer,
		superadmin: superadminReducer,
		account: accountReducer,
		errorlog: errorlogReducer,
		scheduleTemplate: scheduleTemplateReducer,
		sites: sitesReducer,
		siteReports: siteReportsReducer,
		siteScheduleActivity: siteScheduleActivityReducer,
		settings: settingsReducer,
		userAccess: userAccessReducer,
		downloadManager: downloadManagerReducer,
		integration: integrationReducer,
		departmentEditor: departmentEditorReducer,
		users: usersReducer,
		organization: organizationReducer,
		journeyPlan: journeyPlanReducer,
		journeys: journeysReducer,
		departmentGroup: departmentGroupReducer,
		company: companyReducer,
		product: productReducer,
		competitorPromotion: competitorPromotionReducer,
		competitorExport: exportReducer,
		clientLogin: clientLoginReducer,
		featureAccess: featureAccessReducer,
		reqForDemo: reqForDemoReducer,
		promotions: promotionReducer,
		activityLog: activityLogReducer,
		tutorial: tutorialReducer,
		routeAccess: routeReducer,
		filter: filterReducer,
		customerFeedbackReport: customerFeedbackReportReducer,
		siteCompact: siteCompactReducer,
		customerFeedbackList: customerFeedbackListReducer,
		customerFeedbackStatus: customerFeedbackStatusReducer,
		customerFeedbackTemplate: customerFeedbackTemplateReducer,
		siteCompactPaginate: siteCompactPaginateReducer,
		missedMakeupReport: missedMakeupReducer,
		userEnrollment: userEnrollmentReducer,
		formulas: formulasReducer,
		skuCategory: skuCategoryReducer,
		skuUom: skuUomReducer,
		skusCompact: skusCompactReducer,
		siteHierarchy: siteHierarchyReducer,
		usersCompact: usersCompactReducer,
		siteActivityHistory: siteActivityHistoryReducer,
		siteOffDays: siteOffDaysReducer,
		lmsUserList: getUserListReducer,
		lmsCourse: lmsCoursesReducer,
		addLmsUser: addUserReducer,
		createLmsUser: createUserReducer,
		lmsUserGroup: getUserGroupListReducer,
		lmsCoursesSyllabuses: lmsCoursesSyllabusesReducer,
		addLmsUserGroup: createUserGroupReducer,
		bulkUpdateUser: bulkUpdateUser,
		lmsEnrollCourse: getCourseListReducer,
		courses: coursesReducer,
		syllabuses: syllabusesReducer,
		quizManagement: quizManagementReducer,
		quizzes: quizzesReducer,
		lmsCsManagementPage: lmsCsManagementPageReducer,
		lmsUsersCompact: lmsUsersCompactReducer,
		createCourse: createCourseReducer,
		lmsLearnerCourse: lmsCourseReducer,
		lmsLearnerAcitivity: lmsLearnerActivityReducer,
		lmsMyCoursesAndSyllabuses: lmsMyCoursesAndSyllabusesReducer,
		lmsSyllabuses: lmsSyllabusesReducer,
		lmsUserManagementUser: lmsUserManagementUserReducer,
		lmsLearnerCourseQuiz: lmsCourseQuizReducer,
		updateCourse: updateCourseReducer,
		issueTrackerSettings: issueTrackerSettingsReducer,
		settingsNotification: settingsNotificationReducer,
		gallery: galleryReducer,
		reports: reportsReducer,
		quizAssociationOptions: quizAssociationOptionsReducer,
		lmsNotification: lmsNotificationReducer,
		lmsDashboard: lmsDashboardReducer,
		quizEditor: quizEditorReducer,
		healthDashboard: healthDashboardReducer,
		reportsHub: reportsHubReducer,
		repository: repositoryReducer,
		repositoryUserUsage: repositoryUserUsageReducer,
		repositoryOrganizationUsage: repositoryOrganizationUsageReducer,
		repositoryFolderList: repositoryFolderListReducer,
		executiveDashboard: executiveDashboardReducer,
		dashboardOperational: operationalReducer,
		dashboardIssueInsights: issueInsightsReducer,
		reportInsights: reportInsightsReducer,
		basicDashboard: basicDashboardReducer,
		userDeptCompact: userDeptCompactReducer,
		siteGroupCompact: siteGroupCompactReducer,
		siteUserCompact: siteUserCompactReducer,
		userRolesCompact: userRolesCompactReducer,
		lmsSettings: lmsSettingsReducer,
		stockAudit: stockAuditReducer,
		adminDashboard: adminDashboardReducer,
	});
};

export type RootState = StateType<ReturnType<typeof makeRootReducer>>;
