export const SET_USE_AUTO_DUE_DATE = '@settings/SET_USE_AUTO_DUE_DATE';
export const SET_USE_AUTO_ASSIGNMENT = '@settings/SET_USE_AUTO_ASSIGNMENT';
export const SET_HIGHLIGHT_RECURRING_ISSUES = '@settings/SET_HIGHLIGHT_RECURRING_ISSUES';
export const SET_HIGHLIGHT_RECURRING_ISSUES_COUNT = '@settings/SET_HIGHLIGHT_RECURRING_ISSUES_COUNT';
export const SET_ALLOW_AUDITOR_EDIT_ISSUE = '@settings/SET_ALLOW_AUDITOR_EDIT_ISSUE';
export const SET_ALLOW_AUTO_ESCALATION = '@settings/SET_ALLOW_AUTO_ESCALATION';

export const FETCH_ISSUE_TRACKER_SETTINGS_REQUEST = '@settings/FETCH_ISSUE_TRACKER_SETTINGS_REQUEST';
export const FETCH_ISSUE_TRACKER_SETTINGS_SUCCESS = '@settings/FETCH_ISSUE_TRACKER_SETTINGS_SUCCESS';
export const FETCH_ISSUE_TRACKER_SETTINGS_FAILED = '@settings/FETCH_ISSUE_TRACKER_SETTINGS_FAILED';
